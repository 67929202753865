import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Buefy from 'buefy';
import 'buefy/dist/buefy.css';
import { library } from '@fortawesome/fontawesome-svg-core'
//import { faCamera, faUnlockKeyhole } from '@fortawesome/free-solid-svg-icons'
import { faInstagram, faFacebookSquare, faFacebookF, faTiktok, faTwitter, faTwitch, faYoutube, faDiscord, faFacebookMessenger } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import MasonryWall from '@yeger/vue-masonry-wall'
import VueGtag from "vue-gtag"
import 'vue-social-chat/dist/style.css'


library.add(faInstagram, faFacebookSquare, faFacebookF, faTiktok, faTwitter, faTwitch, faYoutube, faDiscord, faFacebookMessenger)

createApp(App)

const app = createApp(App)
app.use(router)
app.use(Buefy)
app.use(MasonryWall)
app.use(VueGtag, {
  config: { id: "G-H777HT5H3Q" }
}, router)
app.component('font-awesome-icon', FontAwesomeIcon)

app.mount('#app')