<template>
  <div class='layout'>
    <!-- ommited -->
    <SocialChat
      icon
      :attendants="attendants"
    >
      <template #header>
        <p>Hi, do you need help? // Ahoj, potřebuješ s něčím poradit?</p>
      </template>
      <template #button>
        <font-awesome-icon :icon="[ 'fab' , 'facebook-messenger' ]" />    
      </template>
      <template #footer>
        <small>I am trying to respond ASAP! // Snažím se odpovídat co nejrychleji!
        </small>
      </template>
    </SocialChat>
  </div>
</template>



<script>
import { SocialChat } from 'vue-social-chat'

export default {
  components: { SocialChat },
  setup () {
    const attendants = [
      {
        app: 'messenger',
        label: 'Online',
        name: 'Verccina',
        number: '1584482118545900',
        avatar: {
          src: 'https://avatars.githubusercontent.com/u/66687713?v=4',
          alt: 'Verccina avatar'
        }
      },
      // ...
    ]

    return { attendants }
  }
}
</script>

<style scoped>
.svg-inline--fa {
  height: 2em;
}
</style>
