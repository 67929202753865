export default [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/support-me',
    name: 'support-me',
    component: () => import('../views/SupportView.vue'),
    children: [
      {
        path: '',
        redirect: '/support-me/discord'
      },
      
      {
        path: 'discord',
        component: () => import('../views/SupportDisView.vue')
      },
      {
        path: 'gallery',
        component: () => import('../views/SupportDHView.vue'),
        children: [
          {
            path: '',
            redirect: '/support-me/gallery/monthly'
          },
          {
            path: 'monthly',
            component: () => import('../views/SupportDHMonthlyView.vue')
          },
          {
            path: 'lifetime',
            component: () => import('../views/SupportDHLifetimeView.vue')
          },
        ]
      },
      {
        path: 'onlyfans',
        component: () => import('../views/SupportOFView.vue')
      },
    ],
  
  },
  {
    path: '/blog',
    name: 'blog',
    component: () => import('../views/BlogView.vue'),
    children: [
      {
        path: '',
        redirect: '/'
      },
      {
        path: 'stone-house',
        component: () => import('../views/blog/StoneHouseView.vue')
      },
      {
        path: 'solar-wind-guide',
        component: () => import('../views/blog/SolarWindGuideView.vue')
      },
      {
        path: 'shower',
        component: () => import('../views/blog/ShowerView.vue')
      },
    ],
  
  }
]