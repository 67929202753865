<template>
<footer>
  <div class="content">
    <div class="link-boxes">
      <ul class="box">
        <li class="link_name">Contact</li>
        <li><a class="email" href="mailto:me@verccina.com">me@verccina.com</a></li>
      
      <div class="media-icons">
          <SocialIcon 
            pack="fab" 
            icon="instagram"
            href="https://www.instagram.com/verccina/">
          </SocialIcon>
          <SocialIcon 
            pack="fab" 
            icon="facebook-f"
            href="https://www.facebook.com/verccina">
          </SocialIcon>
          <SocialIcon 
            pack="fab" 
            icon="twitter"
            href="https://twitter.com/verccina">
          </SocialIcon>
          <SocialIcon 
            pack="fab" 
            icon="tiktok"
            href="https://www.tiktok.com/@verccina">
          </SocialIcon>
          <SocialIcon 
            pack="fab" 
            icon="twitch"
            href="https://www.twitch.tv/verccina">
          </SocialIcon>
          <SocialIcon 
            pack="fab" 
            icon="youtube"
            href="https://www.youtube.com/c/Verccina">
          </SocialIcon>
          <SocialIcon 
            pack="fab" 
            icon="discord"
            href="https://discord.gg/v9cxDJuASs">
          </SocialIcon>
        </div>
        </ul>
      <ul class="box">
        <li class="link_name">Information</li>
        <li>Any reuse without written permission is prohibited. For press inquiries, use of the logo, name and content please contact me.</li>
      </ul>
      <ul class="box">
        <li class="link_name">Any bug?</li>
        <li>I have created this website myself while learning advanced coding (for me) so if there is any glitch, please let me know and I will fix it. Thank you!</li>
      </ul>
      <ul class="box">
        <li class="link_name">Links</li>
        <li><router-link :to="{ path: '/' }">Home</router-link></li>
        <!--<li><router-link :to="{ path: '/support-me' }">Support Me</router-link></li>-->
        <li><a href="https://discord.gg/v9cxDJuASs">Discord</a></li>
        <!-- <li><a href="https://verccina.desirehive.com/">My Gallery</a></li> -->
        <li><a href="https://onlyfans.com/verccina">OnlyFans</a></li>
      </ul>
    </div>
  </div>
  <div class="bottom-details">
    <div class="bottom_text">
      <span class="copyright_text">© 2024 Verccina. All Rights Reserved.
      </span>
    </div>
  </div>
  <div class="cookies">
    I use cookies to understand where you came from and how you interact with the website content. Continue browsing if you agree.
  </div>  
</footer>
</template>

<script>
import SocialIcon from '@/components/SocialIcon.vue';

export default {
  components: { SocialIcon }
}
</script>

<style>
footer {
  background: #111;
  width: 100%;
}

footer .content {
  max-width: 1800px;
  margin: auto;
  padding: 80px 40px 40px 40px;
}

.box {
  background-color: #111 !important;
  font-size: 1em;
  color: #888 !important;
  min-width: 300px;
}

.copyright_text {
  color: #888 !important;
}

.media-icons {
  padding-top: 15px;
}

.media-icons .social-icon {
  margin-right: 20px;
}

footer .content .link-boxes{
  width: 100%;
  display: flex;
  justify-content: space-between;
}

footer .content .link-boxes .box{
  width: calc(100% / 4 - 10px);
}

.content .link-boxes .box .link_name{
  font-family: 'Playfair Display', 'sans-serif';
  color: #fff;
  font-size: 1.2em;
  font-weight: 400;
  margin-bottom: 30px;
  position: relative;
}

.content .link-boxes .box li{
  margin: 6px 0;
  list-style: none;
}
.content .link-boxes .box li a{
  color: #888;
  font-weight: 400;
}

.content .link-boxes .box li a.email {
  color: #f90bc2;
}

.content .link-boxes .box li a.email:hover {
  color: #0091ea;
}

.content .link-boxes .box li a:hover{
  color: #f90bc2;
}

footer .bottom-details{
  width: 100%;
  background: rgb(0, 0, 0);
}

footer .bottom-details .bottom_text{
  margin: auto;
  padding: 20px 40px;
  justify-content: space-between;
  text-align: center;
}

.bottom-details .bottom_text a {
  margin-right: 10px;
}

.cookies {
  text-align: center;
  position: fixed;
  width: 100vw;
  bottom: 0;
  font-size: 9px;
  background: linear-gradient(hsla(0,0%,100%,0),#111);
  color: #888;
  padding-top: 10px;
  padding-bottom: 10px;
}

@media (max-width: 900px) {
  footer .content .link-boxes{
    flex-wrap: wrap;
  }

  footer .bottom-details .bottom_text {
    flex-wrap: wrap;
  }

}

@media (max-width: 700px){
  footer{
    position: relative;
  }

  .media-icons a{
    height: 35px;
    width: 35px;
    font-size: 14px;
    line-height: 35px;
  }

  footer .content .link-boxes .box{
    width: calc(100% / 3 - 10px);
  }
  
  .bottom-details .bottom_text span,
  .bottom-details .bottom_text a{
    font-size: 12px;
  }
}

@media (max-width: 520px){
  .media-icons{
    margin-top: 16px;
  }
  footer .content .link-boxes .box{
    width: calc(100% / 2 - 10px);
  }
}
</style>