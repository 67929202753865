<template>
  <div id="app">
    <MainMenu/>
    <router-view/>
    <CTASection/>
    <DarkFooter/>
    <MessengerChat/>
  </div>
</template>

<script>
import MainMenu from '@/components/MainMenu.vue';
import DarkFooter from '@/components/DarkFooter.vue';
import MessengerChat from '@/components/MessengerChat.vue';

export default {
  components: { MainMenu, DarkFooter, MessengerChat }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600&display=swap');

/* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: thin;
    scrollbar-color: #f90bc2;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 6px;
  }

  *::-webkit-scrollbar-track {
    background: #111;
  }

  *::-webkit-scrollbar-thumb {
    background: linear-gradient(180deg, #f90bc2, #0091ea);
    border-radius: 0;
    border: 0px solid #ffffff;
  }
  
body {
	background: #191C23;
}

#app {
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*padding-top: 109px !important;*/
}

nav {
  padding: 30px;
}

nav a {
  font-weight: 500;
}

.bg-gradient {
  background: rgb(2,0,36);
  background: linear-gradient(90deg, #f90bc2, #0091ea);
  padding-bottom: 3px;
  background-size: 200% 200%;
  animation: navbar-gradient 2s ease infinite;
}

.masonry-column {
  box-sizing: border-box;
  margin: 10px;
}

.masonry-item {
  margin-bottom: 20px;
}
</style>
