<template>
  <b-button tag="a"
    :href="href"
    target="_blank">
      <img :src="image"/>
  </b-button>
</template>

<script> 
export default {
  props: [ 'href', 'image' ]
}
</script>

<style scoped>
.button:not(.is-rounded) {
  border-radius: 0;
  background: linear-gradient(90deg, #f90bc2, #0091ea);
  border: none;
  color: white;
  font-size: 14px;
  box-sizing: border-box;
  width: 50px;
  height: 50px;
}
.button:not(.is-rounded):hover {
  background: #f90bc2;
  color: white;
}

img {
  position: relative;
  top: 2px;
  height: 21px;
}

</style>