<template>
<div class="navbar-outer bg-gradient">
  <nav class="navbar" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
      <router-link :to="{ path: '/' }">
        <img :src="require('../assets/Verccina Full Logo.svg')" alt="V">
      </router-link>

      <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false" @click="menuOpen = !menuOpen">
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>

    <div class="navbar-menu" :style="menuOpen ? 'display: block;' : 'display: none;'">
      <div class="navbar-start">
        <router-link :to="{ path: '/' }" class="navbar-item" @click="scrollToTop">Home</router-link>
        <!-- <router-link :to="{ path: '/support-me' }" class="navbar-item" @click="scrollToTop">Support Me</router-link> -->
      </div>

      <div class="navbar-end">
        <div class="navbar-item">
          <div class="buttons">
            <ImageButton :image="require('../assets/discord-logo-28x21.svg')"
              href="https://discord.gg/v9cxDJuASs">
            </ImageButton>
            <!-- <ImageButton :image="require('../assets/dh-logo-21x21.svg')"
              href="https://verccina.desirehive.com"> 
            </ImageButton>-->
            <ImageButton :image="require('../assets/onlyfans-logo-32x21.svg')"
              href="https://onlyfans.com/verccina">
            </ImageButton>
          </div>
        </div>
      </div>
    </div>
  </nav>
</div>
</template>

<script>
import ImageButton from '@/components/ImageButton.vue';

export default {
  components: { ImageButton },
  data () {
    return {
      menuOpen: false
    }
  },

  methods: { 
    scrollToTop() {
      window.scrollTo(0,0)
    }
  }
}
</script>

<style >

.navbar {
    background-color: #191C23 !important;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
}

.navbar-item, .navbar-link {
    color: white !important;
}

.navbar-item:hover, a.navbar-item:focus {
  background-color: transparent !important;
  color: #f90bc2 !important;
}

.navbar-outer {
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 100;
}

.navbar-brand img {
    max-height: 3.5rem !important;
    padding-right: 10px;
}

@keyframes navbar-gradient {
    0%{background-position:10% 0%}
    50%{background-position:91% 100%}
    100%{background-position:10% 0%}
}

.fa-icons {
    color: #f90bc2 !important;
}

.fa-icons:hover, .fa-icons:active {
    color: #0091ea !important;
    background-color: transparent !important;
}

@media screen and (max-width: 1023px) {
  .navbar-menu {
    background-color: #191C23 !important;
  }
}

.navbar-burger, .navbar-burger:active {
  color: white !important;
}

@media screen and (min-width: 700px) {
  .navbar-menu {
    display: flex !important;
  }
}
</style>